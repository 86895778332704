import * as ReactDOM from 'react-dom/client';
import { EnvironmentProvider } from '@lawcpd/learner/shared/provider';
import { environment } from './environments/environment';
import { BrowserRouter } from 'react-router-dom';

import App from './app/app';
import { FeatureFlagsProvider } from '@lawcpd/feature-flags/provider';

// https://unpkg.com/@ungap/has-own@0.1.1/min.js
/* @ts-ignore */
"hasOwn"in Object||(Object.hasOwn=Object.call.bind(Object.hasOwnProperty));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const app = (
  <BrowserRouter>
    <FeatureFlagsProvider useLearnerFF={false}>
      <App />
    </FeatureFlagsProvider>
  </BrowserRouter>
);

root.render(
  <EnvironmentProvider environment={environment}>
    {app}
  </EnvironmentProvider>
);
