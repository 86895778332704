import styled from 'styled-components';
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { ChatMessageWithToolCall, ImageChatContent } from 'humanloop';
import { ChatEntry, EllipsesEntry } from './chat-entry';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFeatureFlags } from '@lawcpd/feature-flags/provider';

const StyledChat = styled.section`
  font-family: Arial;
  font-size: 16px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  background: #fff;
  position: relative;
  height: 100vh;
  overflow: hidden;

  #message-chat {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    justify-content: flex-start;
    gap: 32px;
    overflow-y: scroll;
    padding: 94px 24px 94px 24px;
    background-color: #FFFFFF;
  }

  .message-conversation {
    display: flex;
    flex-direction: column;
  }

  .message-conversation div.message-user {
    margin-left: auto;
  }

  #message-chat::-webkit-scrollbar {
    width: 6px;
  }

  #message-chat::-webkit-scrollbar-track {
    background: #ddd;
  }

  #message-chat::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  .disabled {
    cursor: not-allowed !important;
    color: #A0A1AA !important;
    background-color: #F7F7FB !important;

    * {
      cursor: not-allowed !important;
      color: #A0A1AA !important;
      background-color: #F7F7FB !important;
    }
  }

  @media only screen and (max-height: 200px ){
    .message-chat{
      padding: 48px 24px 70px 24px;
    }
  }
`;

const StyledHeader = styled.header`
  display: flex;
  padding: 12px 12px 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  position: fixed;
  z-index: 100;

  *:disabled {
    color: #A0A1AA;
  }

  .small-button {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 64px;
    color: #242739;
    border: 1px solid var(--border, rgba(0,0,0,0.10));
    background: var(--bg, #FFF);
    cursor: pointer;
  }

  @media only screen and (max-height: 200px ){
    .small-button{
      padding: 5px;
    }
  }
`;

const StyledFooter = styled.footer`
  display: flex;
  width: 100%;
  padding: 12px 12px 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 0px;
  border-top: 1px solid var(--border, rgba(0,0,0,0.10));
  background: #F7F7FB;
  box-sizing: border-box;

  .message-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40rem;
  }

  .message-input {
    display: flex;
    padding-right: 12px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    color: #737684;
  }

  .message-input textarea{
    flex: 1 0 0;
    color: #737684;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    resize: none;
    border: none;
    outline: none;
    overflow-y: scroll;
    height: 29px;
    font-family: arial;
    background-color: #F7F7FB;
    scrollbar-width: thin;
    scrollbar-color: #ddd;
  }

  .message-input textarea:focus {
    color: black;
  }

  .message-button {
    display: flex;
    font-weight: bold;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 84px;
    color: #737684;
    border: 1px solid var(--border, rgba(0,0,0,0.10));
    background: var(--bg, #FFF);
  }

  @media only screen and (max-width: 320px) {
    gap: 0px;

    .message-input {
      padding-right: 0px;
      gap: 0px;
    }

    .message-input textarea{
      width: 10rem;
    }
  }

  :focus-within {
    border-top: solid 1px #2D48EF;

    .message-button {
      background-color: #2D48EF;
      color: white;
    }
  }
`;

// Because of Humanloop's new typing of ChatMessage I need to declare this
type ChatMessage = Omit<ChatMessageWithToolCall, 'content'> & {
  content: string;
}

type ImageMessage = Omit<ChatMessageWithToolCall, 'content'> & {
  content: ImageChatContent;
}

function useQuery(query: string) {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search).get(query), [search]);
}

function errMessageBuilder(err: string): string { // todo: remove?
  return `Something went wrong with the conversation: **${err}**\n\n Please restart the conversation or report the issue with support`;
}

interface ChatWindowProps {
  isInternal?: boolean;
}

interface DumbChatWindowProps {
  enterTxtInputHandler: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  error: Error;
  initiateConversation: () => void;
  input: string;
  isFinished: boolean;
  loading: boolean;
  messages: ChatMessage[];
  sendMessageHandler: () => void;
  txtInputHandler: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export function DumbChatWindow(props: DumbChatWindowProps) {
  const
    {
      enterTxtInputHandler,
      error,
      initiateConversation,
      input,
      isFinished,
      loading,
      messages,
      sendMessageHandler,
      txtInputHandler,
    } = props,
    isEnabled = !loading && !error && !isFinished;

  // ======== DOM Rendering ======== //
  return(
    <StyledChat>
      <StyledHeader >
        <button className="small-button" onClick={initiateConversation}>Restart the conversation</button>
      </StyledHeader>

      <main id="message-chat">
        <div className="message-conversation">
          {messages.map((m,i) => {
            if(!m.content) return;
            return <ChatEntry text={(m.content)} role={m.role} key={i}/>
          })}
          {error &&
            <ChatEntry text={`${error.name}: ${error.message}`} role='assistant' />
          }
          {loading &&
            <EllipsesEntry role='assistant' />
          }
          {isFinished &&
            <ChatEntry
              text={"Thank you for your time in this conversation, please scroll down to continue or scroll up to restart the conversation."}
              role='assistant'
            />
          }
        </div>
      </main>

      <StyledFooter className={!isEnabled ? "disabled" : ""}>
        <div className="message-box">
          <span className="message-input">
            <textarea
                id="message-input-box"
                placeholder="Enter your message..."
                onKeyDown={enterTxtInputHandler}
                onChange={txtInputHandler}
                value={input}
                disabled={!isEnabled}
              />
          </span>
          <button
            id="send=button"
            onClick={sendMessageHandler}
            className={`message-button`}
            disabled={!isEnabled}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 5l0 14" />
                <path d="M18 11l-6 -6" />
                <path d="M6 11l6 -6" />
              </svg>
          </button>
        </div>
      </StyledFooter>
    </StyledChat>
  );
}

export function ChatWindow(props: ChatWindowProps) {
  const
    { isInternal } = props,
    [ input, setInput ] = useState(''),
    [ error, setError ] = useState<Error>(),
    [ loading, setLoading ] = useState(true),
    [ isFinished, setIsFinished ] = useState(false),
    { loading: ffLoading, featureFlags } = useFeatureFlags(),
    { course: { api } } = useEnvironment(),
    [ messages, setMessages ] = useState<ChatMessage[]>([]),
    limit = useQuery("l"), // get limit
    { conversationName } = useParams<{ conversationName: string }>();

  // ======== Effect Hooks ======== //
  //#region
  // Run once to begin conversation
  useEffect(() => {
    if(!ffLoading && (!featureFlags.USE_COURSE_CHAT_AUTH_10705)){
      initiateConversation();
    }
    else if(!ffLoading && ( featureFlags.USE_COURSE_CHAT_AUTH_10705)){
      setLoading(false);
      setError(new Error('This conversation is reserved for learners only, please log-in LawCPD.'))
    }
  }, [ffLoading]);

  useEffect(() => {
    const assistantMessages = messages.filter((m) => m.role === 'assistant');
    if(limit && (assistantMessages.length >= +limit)) setIsFinished(true);
    else setIsFinished(false);

    if(assistantMessages.length > 1){
      const
        messageChat = document.getElementById("message-chat"),
        responses = document.getElementsByClassName("message-text"),
        latestResponse = responses[responses.length-1],
        elemPosition = latestResponse.getBoundingClientRect().top,
        scrollPosition = elemPosition + (messageChat.scrollTop - 50); // 50 offset header height

      messageChat.scrollTo({
        top: scrollPosition,
        behavior: "smooth"
      });
    }
  }, [messages.length]);
  //#endregion

  // ======== Event Handlers ======== //
  //#region
  const initiateConversation = () => {
    setError(null);
    setLoading(true);
    setMessages([]);
    sendMessage([{
      content: 'begin the conversation',
      role: 'user'
    }])
    .then((m) => {
      setLoading(false);
      if(!m) return;
      setMessages([m]);

    })
    .catch((e) => {
      setLoading(false);
      // setError('Something went wrong while initiating the conversation.');
      setError(e);
    });
  }

  const pollMessage = async (key: string, retries = 8): Promise<ChatMessage> => {
    // Average response from OpenAI is 30s ~ 60s
    // Initial response from message endpoint waits up to 20s
    // Remaining is 40s, with interval of 5s
    const
      options: any = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      };

    const response = await fetch(`${api}/api/chat/message/${key}`, options);

    // Result may not have been written yet, poll for it
    if(response.status === 404){
      return new Promise(async (res, rej) => {
        // setTimeout seems much more appropriate here for handling retries and readability than setInterval.
        setTimeout(() => {

          if(retries > 0)
            res(pollMessage(key, retries-1));
          else
            rej('Ran out of retries polling for message result.');
        }, 5000);
      })
    }
    else if(!response.ok) {
      console.error(response);
      const defaultErr = `${response.status} : ${response.statusText}`;

      return await response.json()
      .then((val) => {
        throw new Error((val && val.content)|| defaultErr);
      });
    };

    const assistantMessage: ChatMessage = await response.json();

    return assistantMessage;

  }

  const sendMessage = async (toSend: ChatMessage[]): Promise<ChatMessage> => {
    const
      payload = {
        data: {
          message: toSend,
          project: conversationName,
          isInternal: isInternal
        },
        group: 'chat',
        name: 'message'
      },
      options: any = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload),
      };

    const response = await fetch(`${api}/api/chat/message`, options);

    if(!response.ok) {
      console.error(response);
      const defaultErr = `${response.status} : ${response.statusText}`;

      return await response.json()
      .then((val) => {
        throw new Error((val && val.content) || defaultErr);
      });
    };

    const annotatedChatMessage = await response.json();

    if(annotatedChatMessage.key){ // Initial timeout, start polling the result
      return pollMessage(annotatedChatMessage.key, 8);
    }
    else{ // Response received in time
      return annotatedChatMessage;
    }
  }

  const sendMessageHandler = async () => {
    if(!input) return;

    const userMessage: ChatMessage = {
      role: 'user',
      content: input
    };

    const newMessages = [...messages, userMessage];
    setInput('');
    setMessages(newMessages);
    setLoading(true);
    try{
      const
        assistantMessage = await sendMessage(newMessages),
        content = assistantMessage.content.toLocaleLowerCase();

      if(content.includes('this marks the end of the conversation')){
        setIsFinished(true);
      }

      setMessages([...newMessages, assistantMessage]);
      const textbox = document.getElementById("message-input-box");

      textbox.style.height = '29px';
    }
    catch(e){
      console.error(e);
      setError(e);
    }
    setLoading(false);
  }

  const txtInputHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const
      textArea = e.target,
      val = textArea.value;

    textArea.style.height = '29px';
    textArea.style.height = (textArea.scrollHeight < 116 ? textArea.scrollHeight : 116) +"px";
    setInput(val);
  };

  const enterTxtInputHandler = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const textArea = e.target as HTMLTextAreaElement;
    if(e.key === 'Enter' && !e.shiftKey){
      e.preventDefault();
      sendMessageHandler();

      textArea.style.height = '29px';
    }
  };
  //#endregion

  // ======== DOM Rendering ======== //
  return (
    <DumbChatWindow
      enterTxtInputHandler={enterTxtInputHandler}
      error={error}
      initiateConversation={initiateConversation}
      input={input}
      isFinished={isFinished}
      loading={loading}
      messages={messages}
      sendMessageHandler={sendMessageHandler}
      txtInputHandler={txtInputHandler}
    />
  )
}
