import { theme } from '@lawcpd/learner/shared/ui';
import { ChatRole } from 'humanloop';
import { useState } from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';
import BotIcon from '../assets/bot-icon-gray.png';

const StyledChatEntry = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  flex-direction: column;

  :last-of-type {
    margin: 0;
  }

  .message-img {
    border-radius: 150px;
    position: relative;
  }

  .img-user, .img-bot {
    width: 24px;
    height: 24px;
    margin: 0;
  }

  .message-bot .message-text {
    color: #54586B;
  }

  .message-user .message-text {
    color: #242739;
    background-color: rgba(45, 75, 239, 0.05);
    border-radius: 8px 8px 0px 8px;
  }


  .bubble.message-user {
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row-reverse;
  }

  .bubble.message-bot {
    align-items: flex-start;
  }

  .message-info {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    color: var(--text, #54586B);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    margin-bottom: 5px;
  }

  .message-info.message-user {
    margin-left: auto;
  }
`;

const StyledChatBubble = styled.div`
  display: flex;
  gap: 8px;
  align-self: stretch;
  max-width: 550px;

  .message-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .message-info-name {
    margin-right: 10px;
    font-weight: bold;
  }

  .message-text {
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 8px;
    padding: 0px 1rem;
  }

  .message-text p {
    word-wrap: anywhere;
    margin: 1rem 0px;
  }

  .message-info-time {
    font-size: 0.85em;
  }
`;

const StyledEllipses = styled.span`
  span {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
  }

  span:nth-child(1) {
    -webkit-animation-delay: 0.0s;
    animation-delay: 0.0s;
  }

  span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  @-webkit-keyframes ellipsis-dot {
      0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }

  @keyframes ellipsis-dot {
      0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
`;

const StudentAvatar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="message-img img-user"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="#29356F"
      strokeLinecap="round"
      strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"></path>
        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"></path>
    </svg>
  )
};

const BotAvatar = () => {
  return (
    <img src={BotIcon} className='message-img img-bot'/>
  )
}

type ChatConfig = {
  name: string;
  direction: 'row' | 'row-reverse';
  avatar: JSX.Element;
  bubbleClass: string;
};

const chatEntryConfig = (r: ChatRole): ChatConfig => {
  switch(r){
    case 'user':
      return {
        name: 'You',
        direction: 'row-reverse',
        avatar: StudentAvatar(),
        bubbleClass: 'message-user'
      };
    default:
      return {
        name: 'AI Bot',
        direction: 'row',
        avatar: BotAvatar(),
        bubbleClass: 'message-bot'
      }
  }
};

interface ChatEntryProps {
  text: string;
  role: ChatRole;
};

interface EllipsesProps {
  text?: string;
  role: ChatRole;
};

/**
 * The chat entry complete with text, avatar, and date
 * @param props Takes in a text to display and the role of whom the chat entry came from
 * @returns A complete chat bubble with text, avatar, and date
 */
export function ChatEntry(props: ChatEntryProps){
  const
    { text, role } = props,
    [timeStamp, setTimestamp] = useState(new Date()),
    chatConfig = chatEntryConfig(role);

  return (
    <StyledChatEntry className={chatConfig.bubbleClass}>
      <div className={`message-info ${chatConfig.bubbleClass}`}>
        <div className="message-info-name"><b>{chatConfig.name}</b></div>
        <div className="message-info-time">
          {timeStamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </div>
      </div>
      <StyledChatBubble className={`bubble ${chatConfig.bubbleClass}`}>
        <div className="message-img">
          {chatConfig.avatar}
        </div>
        <div className="message-text">
          <Markdown>{text}</Markdown>
        </div>
      </StyledChatBubble>
    </StyledChatEntry>
  )
};

/**
 * For loading response-in-progress animation
 * @param props Takes in the role of either the user or the bot role
 * @returns The chat entry without date and just ellipses animation
 */
export function EllipsesEntry(props: EllipsesProps){
  const
    { text, role } = props,
    chatConfig = chatEntryConfig(role);

  return(
    <StyledChatEntry className={chatConfig.bubbleClass}>
        <div className={`message-info ${chatConfig.bubbleClass}`}>
            <div className="message-info-name"><b>{chatConfig.name}</b></div>
            <div className="message-info-time">{" is thinking..."}</div>
        </div>
        <StyledChatBubble className={`bubble ${chatConfig.bubbleClass}`}>
          <div className="message-img">
            {chatConfig.avatar}
          </div>
          <div className="message-text">
            <Markdown>
              {text}
            </Markdown>
            <StyledEllipses>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </StyledEllipses>
          </div>
        </StyledChatBubble>
    </StyledChatEntry>
  )
}

export default ChatEntry;
