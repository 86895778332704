import styled from 'styled-components';

import { Route, Routes } from 'react-router-dom';
import { useFeatureFlags } from '@lawcpd/feature-flags/provider';
import { ChatWindow as ChatWindowV1 } from './chat-window-old';
import { ChatWindow as ChatWindowV2 } from './chat-window';

const StyledApp = styled.div`
  // Your style here
`;

export function App() {
  const
    { loading: ffLoading, featureFlags: { DISPLAY_UPDATED_CHATBOT_11328} } = useFeatureFlags(),
    ChatWindow = DISPLAY_UPDATED_CHATBOT_11328 ? ChatWindowV2 : ChatWindowV1;

  return (
    <StyledApp>
      {ffLoading && <div>Loading...</div>}
      {!ffLoading && <Routes>
        <Route path="/public/:conversationName" element={
          <ChatWindow isInternal={false}/>
        }/>
        <Route path="/:conversationName" element={
          <ChatWindow isInternal={true}/>
        }/>
      </Routes>}
    </StyledApp>
  );
}

export default App;
