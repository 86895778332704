import styled from 'styled-components';
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { ChatMessageWithToolCall, ImageChatContent } from 'humanloop';
import { ChatEntry, EllipsesEntry } from './chat-entry-old';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { useFeatureFlags } from '@lawcpd/feature-flags/provider';

const StyledChat = styled.section`
  font-family: sans-serif;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  background: #fff;

  .msger-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 2px solid #ddd;
    background: #eee;
    color: #666;
  }

  .msger-chat {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #fcfcfe;
  }

  .msger-chat::-webkit-scrollbar {
    width: 6px;
  }

  .msger-chat::-webkit-scrollbar-track {
    background: #ddd;
  }

  .msger-chat::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  .msger-inputarea {
    display: flex;
    padding: 10px;
    border-top: 2px solid #ddd;
    background: #eee;
  }

  .msger-inputarea * {
    padding: 10px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
  }

  .msger-input {
    flex: 1;
    background: #ddd;
  }

  .msger-send-btn {
    margin-left: 10px;
    background: rgb(0, 196, 65);
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
  }

  .msger-send-btn:hover {
    background: rgb(0, 180, 50);
  }

  .disabled {
    cursor: not-allowed;
  }

  button.disabled{
    margin-left: 10px;
    background: #ddd;
  }
`;

// Because of Humanloop's new typing of ChatMessage I need to declare this
type ChatMessage = Omit<ChatMessageWithToolCall, 'content'> & {
  content: string;
}

type ImageMessage = Omit<ChatMessageWithToolCall, 'content'> & {
  content: ImageChatContent;
}

function useQuery(query: string) {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search).get(query), [search]);
}

function errMessageBuilder(err: string): string { // todo: remove?
  return `Something went wrong with the conversation: **${err}**\n\n Please restart the conversation or report the issue with support`;
}

interface ChatWindowProps {
  isInternal?: boolean;
}

export function ChatWindow(props: ChatWindowProps) {
  const
    { isInternal } = props,
    [ input, setInput ] = useState(''),
    [ error, setError ] = useState<Error>(),
    [ loading, setLoading ] = useState(true),
    [ isFinished, setIsFinished ] = useState(false),
    isEnabled = !loading && !error && !isFinished,
    { loading: ffLoading, featureFlags } = useFeatureFlags(),
    { course: { api } } = useEnvironment(),
    [ messages, setMessages ] = useState<ChatMessage[]>([]),
    limit = useQuery("l"), // get limit
    { conversationName } = useParams<{ conversationName: string }>();

  // ======== Effect Hooks ======== //
  //#region
  // Run once to begin conversation
  useEffect(() => {
    if(!ffLoading && (!featureFlags.USE_COURSE_CHAT_AUTH_10705)){
      initiateConversation();
    }
    else if(!ffLoading && ( featureFlags.USE_COURSE_CHAT_AUTH_10705)){
      setLoading(false);
      setError(new Error('This conversation is reserved for learners only, please log-in LawCPD.'))
    }
  }, [ffLoading]);

  useEffect(() => {
    const assistantMessages = messages.filter((m) => m.role === 'assistant');
    if(limit && (assistantMessages.length >= +limit)) setIsFinished(true);
    else setIsFinished(false);
  }, [messages.length]);
  //#endregion

  // ======== Event Handlers ======== //
  //#region
  const initiateConversation = () => {
    setError(null);
    setLoading(true);
    setMessages([]);
    sendMessage([{
      content: 'begin the conversation',
      role: 'user'
    }])
    .then((m) => {
      setLoading(false);
      if(!m) return;
      setMessages([m]);

    })
    .catch((e) => {
      setLoading(false);
      // setError('Something went wrong while initiating the conversation.');
      setError(e);
    });
  }

  const pollMessage = async (key: string, retries = 8): Promise<ChatMessage> => {
    // Average response from OpenAI is 30s ~ 60s
    // Initial response from message endpoint waits up to 20s
    // Remaining is 40s, with interval of 5s
    const
      options: any = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      };

    const response = await fetch(`${api}/api/chat/message/${key}`, options);

    // Result may not have been written yet, poll for it
    if(response.status === 404){
      return new Promise(async (res, rej) => {
        // setTimeout seems much more appropriate here for handling retries and readability than setInterval.
        setTimeout(() => {

          if(retries > 0)
            res(pollMessage(key, retries-1));
          else
            rej('Ran out of retries polling for message result.');
        }, 5000);
      })
    }
    else if(!response.ok) {
      console.error(response);
      const defaultErr = `${response.status} : ${response.statusText}`;

      return await response.json()
      .then((val) => {
        throw new Error((val && val.content)|| defaultErr);
      });
    };

    const assistantMessage: ChatMessage = await response.json();

    return assistantMessage;

  }

  const sendMessage = async (toSend: ChatMessage[]): Promise<ChatMessage> => {
    const
      payload = {
        data: {
          message: toSend,
          project: conversationName,
          isInternal: isInternal
        },
        group: 'chat',
        name: 'message'
      },
      options: any = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload),
      };

    const response = await fetch(`${api}/api/chat/message`, options);

    if(!response.ok) {
      console.error(response);
      const defaultErr = `${response.status} : ${response.statusText}`;

      return await response.json()
      .then((val) => {
        throw new Error((val && val.content) || defaultErr);
      });
    };

    const annotatedChatMessage = await response.json();

    if(annotatedChatMessage.key){ // Initial timeout, start polling the result
      return pollMessage(annotatedChatMessage.key, 8);
    }
    else{ // Response received in time
      return annotatedChatMessage;
    }

  }

  const sendMessageHandler = async () => {
    if(!input) return;

    const userMessage: ChatMessage = {
      role: 'user',
      content: input
    };

    const newMessages = [...messages, userMessage];
    setInput('');
    setMessages(newMessages);
    setLoading(true);
    try{
      const
        assistantMessage = await sendMessage(newMessages),
        content = assistantMessage.content.toLocaleLowerCase();

      if(content.includes('this marks the end of the conversation')){
        setIsFinished(true);
      }

      setMessages([...newMessages, assistantMessage]);
    }
    catch(e){
      console.error(e);
      setError(e);
    }
    setLoading(false);
  }

  const txtInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setInput(val);
  };

  const enterTxtInputHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      sendMessageHandler();
    }
  };
  //#endregion

  // ======== DOM Rendering ======== //
  return(
    <StyledChat>
      <header className="msger-header">
        <button onClick={initiateConversation}>
          Restart the conversation
        </button>
      </header>

      <main className="msger-chat">
        {messages.map((m,i) => {
          if(!m.content) return;
          return <ChatEntry text={(m.content)} role={m.role} key={i}/>
        })}
        {error &&
          <ChatEntry text={`${error.name}: ${error.message}`} role='assistant' />
        }
        {loading &&
          <EllipsesEntry role='assistant' />
        }
        {isFinished &&
          <ChatEntry
            text={"Thank you for your time in this conversation, please scroll down to continue or scroll up to restart the conversation."}
            role='assistant'
          />
        }
      </main>

      <div className="msger-inputarea">
        <input
          type="text"
          className={`${!isEnabled ? 'disabled' : ''} msger-input`}
          placeholder="Enter your message..."
          onKeyDown={enterTxtInputHandler}
          onChange={txtInputHandler}
          value={input}
          disabled={!isEnabled}
        />
        <button
          onClick={sendMessageHandler}
          className={`${!isEnabled ? 'disabled' : 'msger-send-btn'}`}
          disabled={!isEnabled}
        >
          Send
        </button>
      </div>
    </StyledChat>
  )
}
